import * as PIXI from 'pixi.js';

import { Variables } from '../../config';

export const FREE_SPINS_TREASURE_ICON_POS_X = 780;
export const FREE_SPINS_TREASURE_ICON_POS_Y = -100;
export const FREE_SPINS_TREASURE_ICON_SCALE = 0.5;

export const FREE_SPINS_COUNTER_POS_X = 840;
export const FREE_SPINS_COUNTER_POS_Y = -30;

export const FREE_SPINS_COUNTER_ANIMATION_SCALE = 1.5;
export const FREE_SPINS_COUNTER_ANIMATION_DELAY = 500;
export const FREE_SPINS_COUNTER_ANIMATION_LOOP = false;

export const FREE_SPINS_TITLE_MOVE_SCALE = 0.6;
export const FREE_SPINS_TITLE_MOVE_SPIN_SCALE = 0.8;

const GRADIENT_FIRE_TEXT_COLOR_CONFIG = {
  fill: ['#F47B99', '#F6B73F', '#F5E7B5'],
  fillGradientStops: [0.1, 0.5, 0.9],
  fillGradientType: 0,
  stroke: '#651717',
  dropShadowColor: 0x333333,
  dropShadowDistance: 3,
};

export const textStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_FIRE_TEXT_COLOR_CONFIG,
  fontSize: 75,
  miterLimit: 0,
  strokeThickness: 7,
  lineJoin: 'round',
});

export const spinsStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_FIRE_TEXT_COLOR_CONFIG,
  fontSize: 75,
  miterLimit: 0,
  strokeThickness: 7,
  lineJoin: 'round',
});
