import * as PIXI from 'pixi.js';

import { Colors, Variables } from '../../config';

const winMessage = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 200,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 4,
  padding: 4,
  stroke: '#110000',
  strokeThickness: 9,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 160,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 4,
  padding: 4,
  stroke: '#110000',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

export const miniPayTableNumberStyle = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 26,
  fill: '0xffffff',
  trim: true,
};

export const miniPayTableTextStyle = {
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 26,
  fill: '0xf6e87e',
  trim: true,
};

export const bottomContainerTextStyle = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fill: '#ffffff',
    fontSize: 26,
    lineHeight: 26,
    trim: true,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_LANDSCAPE_FIT_TO_WIDTH = 120;
export const FEATURE_BTN_TEXT_PORTRAIT_FIT_TO_WIDTH = 250;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 2;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  stroke: '#000000',
  strokeThickness: 4,
  padding: 5,
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#505050',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 31,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  stroke: '#dddddd',
  strokeThickness: 2,
  padding: 5,
});
export const buyFeatureClickTextStyle = buyFeatureTextStyle;
export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#101010', '#383838', '#727272', '#383838', '#101010'],
  stroke: '#000000',
  strokeThickness: 2,
  padding: 5,
});

export const bigWinStyles = new PIXI.TextStyle(winMessage);
export const megaWinStyles = new PIXI.TextStyle(winMessage);
export const greatWinStyles = new PIXI.TextStyle(winMessage);
export const epicWinStyles = new PIXI.TextStyle(winMessage);
export const winValueStyles = new PIXI.TextStyle(winCounterStyle);

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };

export const autoPlayCounterTextStyle = new PIXI.TextStyle({
  fontWeight: 'bold',
  fontFamily: Variables.FONT_FAMILY,
  stroke: 'black',
  strokeThickness: 4,
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: Colors.GAME_COLOR,
  lineJoin: 'round',
});

export const clockTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const gameNameTextStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontWeight: 'bold',
  fontSize: 20,
  whiteSpace: 'normal',
  align: 'center',
  fill: 0xffffff,
  trim: true,
});

export const soundToastStyles = {
  default: {
    fontFamily: Variables.FONT_FAMILY,
    fontSize: 40,
    fill: '#ffffff',
    stroke: 'black',
    strokeThickness: 5,
  },
  color: {
    fill: Colors.GAME_COLOR,
  },
};

export const miniPayTableStyle = {
  fontSize: 28,
  lineHeight: 50,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
};

export const miniPayTableAdditionalTextStyle = new PIXI.TextStyle({
  fontSize: 28,
  lineHeight: 30,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  fill: '#ffffff',
  stroke: '#000000',
  strokeThickness: 5,
  lineJoin: 'round',
  trim: true,
  wordWrap: true,
  wordWrapWidth: 220,
  breakWords: true,
  align: 'center',
});
