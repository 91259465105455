import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = -58;

export const MESSAGE_FREE_SPINS_BANNER_Y = -GAME_CONTAINER_HEIGHT / 4 + 20;
export const MESSAGE_FREE_SPINS_BANNER_TITLE_Y = 110;
export const MESSAGE_FREE_SPINS_BANNER_DESCRIPTION_Y = 230;
export const MESSAGE_FREE_SPINS_BANNER_DESCRIPTIONS1_Y = 650;
export const MESSAGE_FREE_SPINS_BANNER_BUTTON_Y = 740;

export const MESSAGE_WIN_BANNER_Y = MESSAGE_FREE_SPINS_BANNER_Y;
export const MESSAGE_WIN_BANNER_TITLE_Y = 200;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_X = 20;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 280;
export const MESSAGE_NEXT_BASE_GAME_Y = 580;
