import * as PIXI from 'pixi.js';

import { Variables } from '../../config';

const GRADIENT_GOLD_AMOUNT_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#FFFC00', '#CE7A23', '#FFFC00', '#CE7A23', '#FFFC00', '#CE7A23'],
  fillGradientStops: [0.1, 0.2, 0.4, 0.6, 0.8],
  fillGradientType: 0,
  stroke: '#151515',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 3,
  miterLimit: 4,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#FFFC00', '#CE7A23', '#FFFC00', '#CE7A23', '#FFFC00', '#CE7A23'],
  fillGradientStops: [0.1, 0.2, 0.4, 0.6, 0.8],
  fillGradientType: 0,
  stroke: '#151515',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 3,
  miterLimit: 4,
};

export const titleTextStyle = new PIXI.TextStyle({
  fontSize: 100,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
  lineJoin: 'round',
  padding: 20,
});

export const descriptionsTextStyle = new PIXI.TextStyle({
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFFFFF',
  stroke: '#000000',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAngle: 1,
  dropShadowDistance: 4,
  miterLimit: 4,
  lineJoin: 'round',
  padding: 10,
});

export const btnTextStyle = new PIXI.TextStyle({
  fontSize: 60,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#ff0060',
  stroke: '#ffffff',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAngle: 1.0,
  dropShadowDistance: 4,
  miterLimit: 4,
  lineJoin: 'round',
  padding: 12,
});

export const totalWinTitleStyles = new PIXI.TextStyle({
  ...titleTextStyle,
  fontSize: 120,
  padding: 24,
});

export const totalWinAmountTextStyles = new PIXI.TextStyle({
  fontSize: 160,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 8,
  lineJoin: 'round',
  padding: 32,
});
